import React from "react"
import style from "./plans-slide.mod.scss"
import SlideSurface from "./slide-surface"
import Tick from "../icons/tick"

const PlansSlide = ({
  planPrice,
  planType,
  planBillingPeriod,
  planFeatures,
  highlighted,
}) => {
  return (
    <SlideSurface invertBg hasBorder isFav={highlighted}>
      <div className={style.plan}>
        <div className={style.plan__content}>
          <h5 className={style.plan__type}>{planType}</h5>
          <h4 className={style.plan__price}>{planPrice}</h4>
          <div className={style.plan__billing}>
            <p className={style.plan__pill}>{planBillingPeriod}</p>
          </div>
        </div>
        <div className={style.plan__divider} />
        <ul className={style.plan__features}>
          {planFeatures.map((item) => (
            <li className={style.plan__feature} key={item.id}>
              <Tick />
              <span>{item.text}</span>
            </li>
          ))}
        </ul>
      </div>
    </SlideSurface>
  )
}

export default PlansSlide

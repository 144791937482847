import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import style from "./cases-slide.mod.scss"
import SlideDownload from "./slide-download"
import SlideSurface from "./slide-surface"

const CasesSlide = ({ image, heading, text, file }) => {
  return (
    <SlideSurface>
      <div className={style.case}>
        <div className={style.case__image}>
          <GatsbyImage image={image.gatsbyImageData} alt={image.alt} />
        </div>
        <div className={style.case__content}>
          <div>
            <p className={style.case__heading}>{heading}</p>
            <p className={style.case__text}>{text}</p>
          </div>
          {file && (
            <div className={style.case__download}>
              <SlideDownload title={file.title} url={file.url} />
            </div>
          )}
        </div>
      </div>
    </SlideSurface>
  )
}

export default CasesSlide

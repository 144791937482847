import React from "react"
import style from "./content.mod.scss"
import { motion } from "framer-motion"
import ButtonWrap from "../../components/global/button-wrap"
import BrandMark from "../../components/icons/brand-mark"

const Content = ({ heading, subHeading, paragraphText, links }) => {
  const variants = {
    offscreen: {
      y: 40,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.2,
        duration: 1.5,
        delay: 0.25,
      },
    },
  }

  return (
    <motion.div
      className={style.content}
      variants={variants}
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ amount: 0.5, once: true }}
    >
      <div className={style.content__brandmark}>
        <BrandMark />
      </div>
      <div>
        {heading && (
          <h2>
            {heading} <br /> {subHeading}
          </h2>
        )}
      </div>
      <div dangerouslySetInnerHTML={{ __html: paragraphText }}></div>
      <div className={style.content__buttons}>
        <ButtonWrap links={links} />
      </div>
    </motion.div>
  )
}

export default Content

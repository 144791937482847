import { GatsbyImage } from "gatsby-plugin-image"
import Container from "../../components/container/container"
import React from "react"
import style from "./gateway.mod.scss"
import Content from "../../components/content/content"

const Gateway = ({
  content: { heading, subHeading, paragraphText, backgroundImage, links },
}) => {
  return (
    <div className={style.gateway}>
      <Container gutters width="large">
        <div className={style.gateway__content}>
          <div>
            <GatsbyImage
              image={backgroundImage.gatsbyImageData}
              alt={backgroundImage.alt}
            />
          </div>
          <div className={style.gateway__content_wrap}>
            <Content
              heading={heading}
              subHeading={subHeading}
              paragraphText={paragraphText}
              links={links}
            />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Gateway

import Container from "../../components/container/container"
import React from "react"
import style from "./products.mod.scss"
import Slider from "../../components/slider/slider"
import ButtonWrap from "../../components/global/button-wrap"

const Products = ({ content: { products, links, heading } }) => {
  return (
    <div className={style.products}>
      <div className={style.products__wrap}>
        <Container gutters pt={1} pb={1} width="large">
          <div className={style.products__heading}>
            <h2>{heading}</h2>
          </div>
          <div className={style.products__items}>
            <Slider
              slides={products}
              slideTemplate={"product"}
              settings={{
                spaceBetween: 50,
                breakpoints: {
                  800: {
                    slidesPerView: 2,
                  },
                  1250: {
                    slidesPerView: 3,
                  },
                },
              }}
            />
          </div>
          <div className={style.products__buttons}>
            <ButtonWrap links={links} />
          </div>
        </Container>
      </div>
    </div>
  )
}

export default Products

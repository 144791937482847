import Container from "../../components/container/container"
import React from "react"
import style from "./use-cases.mod.scss"
import Slider from "../../components/slider/slider"
import ButtonWrap from "../../components/global/button-wrap"

const UseCases = ({ content: { items, links } }) => {
  return (
    <div className={style.cases}>
      <Container gutters pt={2} pb={1} width="large">
        <div className={style.cases__heading}>
          <h2>Use Cases</h2>
        </div>
        <div className={style.cases__items}>
          <Slider
            slides={items}
            settings={{
              breakpoints: {
                700: {
                  slidesPerView: 2,
                },
                1050: {
                  slidesPerView: 3,
                },
                1400: {
                  slidesPerView: 4,
                },
              },
            }}
          />
        </div>
        <div className={style.cases__buttons}>
          <ButtonWrap links={links} />
        </div>
      </Container>
    </div>
  )
}

export default UseCases

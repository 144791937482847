import React from "react"
import { graphql } from "gatsby"
import Section from "../components/global/section"
import Layout from "../components/global/layout"
import Hero from "../sections/hero/hero"
import Gateway from "../sections/gateway/gateway"
import Industrial from "../sections/industrial/industrial"
import Vehicle from "../sections/vehicle/vehicle"
import Portable from "../sections/portable/portable"
import UseCases from "../sections/use-cases/use-cases"
import Plans from "../sections/plans/plans"
import Products from "../sections/products/products"
import Works from "../sections/works/works"
import arcSvg from "../assets/images/arc.svg"
import Contact from "../sections/contact/contact"
import Footer from "../components/footer/footer"


const Index = ({
  location,
  data: {
    hero,
    gateway,
    industrial,
    vehicle,
    portable,
    datoCmsHomeSeo,
    datoCmsSite,
    useCases,
    plans,
    products,
    howItWorks,
    footer,
  },
}) => {
  return (
    <>
      <Layout
        seo={datoCmsHomeSeo.seoMetaTags}
        favicon={datoCmsSite.faviconMetaTags}
        location={location}
      >
        <Section id={"hero"}>
          <Hero content={hero} />
        </Section>
        <Section id={"gateway"}>
          <Gateway content={gateway} />
        </Section>
        <Section id={"indistrial"}>
          <Industrial content={industrial} />
        </Section>
        <Section id={"vehicle"}>
          <Vehicle content={vehicle} />
        </Section>
        <Section id={"portable"}>
          <Portable content={portable} />
        </Section>
        <Section id={"cases"}>
          <UseCases content={useCases} />
        </Section>
        <Section id={"works"}>
          <Works content={howItWorks} />
        </Section>
        <Section id={"arc"} arc>
          <img src={arcSvg} alt="" />
        </Section>
        <Section id={"plans"}>
          <Plans content={plans} />
        </Section>
        <Section id={"products"}>
          <Products content={products} />
        </Section>
        <Section gradient id={"contact"}>
          <Contact />
          <Footer {...footer} />
        </Section>
      </Layout>
    </>
  )
}
export default Index

// Need to search the graphql layer? http://localhost:8000/___graphql
export const query = graphql`
  {
    datoCmsHomeSeo {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    datoCmsSite {
      faviconMetaTags {
        tags
      }
    }
    hero: datoCmsHeroSection {
      heading
      paragraphText
      backgroundImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { w: "1920", fit: "crop", q: 100 }
        )
        alt
      }
      backgroundVideo {
        url
        video {
          mp4Url
          streamingUrl
          thumbnailUrl
        }
      }
      links: link {
        ...Link
      }
    }
    gateway: datoCmsRivirGatewaySection {
      heading
      subHeading
      paragraphText
      backgroundImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { w: "1200", fit: "crop", q: 100 }
        )
        alt
      }
      links: link {
        ...Link
      }
    }
    industrial: datoCmsRivirIndustrialSection {
      heading
      subHeading
      paragraphText
      backgroundImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { w: "400", fit: "crop", q: 100 }
        )
        alt
      }
      links: link {
        ...Link
      }
    }
    vehicle: datoCmsRivirInvehicleSection {
      heading
      subHeading
      paragraphText
      backgroundImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { w: "800", fit: "crop", q: 100 }
        )
        alt
      }
      links: link {
        ...Link
      }
    }
    portable: datoCmsRivirPortableSection {
      heading
      subHeading
      paragraphText
      backgroundImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { w: "1200", fit: "crop", q: 100 }
        )
        alt
      }
      links: link {
        ...Link
      }
    }
    useCases: datoCmsUseCasesSection {
      id: originalId
      links: link {
        ...Link
      }
      items: useCases {
        id: originalId
        heading
        text: paragraphText
        file: downloadableFile {
          url
          title
        }
        image {
          gatsbyImageData(
            layout: FULL_WIDTH
            imgixParams: { w: "434", h: "332", fit: "crop", q: 75 }
          )
          alt
        }
      }
    }
    plans: datoCmsDataPlansSection {
      id: originalId
      heading
      links: link {
        ...Link
      }
      plans: dataPlans {
        planType
        planPrice
        planBillingPeriod
        id: originalId
        highlighted
        planFeatures {
          text
          id: originalId
        }
      }
    }
    products: datoCmsProductDetailsSection {
      originalId
      heading
      links: link {
        ...Link
      }
      products {
        id: originalId
        title
        paragraphText
        altImages: alternativeImages {
          id: originalId
          image {
            gatsbyImageData(
              layout: CONSTRAINED
              imgixParams: { w: "75", fit: "crop", q: 60 }
            )
          }
        }
        image: productImage {
          gatsbyImageData(
            layout: CONSTRAINED
            imgixParams: { h: "280", fit: "crop", q: 80 }
          )
        }
        characteristics {
          title
          id: originalId
        }
        downloads {
          id: originalId
          pdf {
            url
            title
          }
          title
        }
      }
    }
    howItWorks: datoCmsHowItWorksSection {
      heading
      paragraphText
      secondaryParagraphText
      links: link {
        ...Link
      }
      items {
        text: paragraphText
        icon {
          url
        }
      }
    }
    footer: datoCmsFooterSection {
      heading
      links {
        ...Link
      }
      address
      credit
      email
      linkedin
      url
      urlText
      privacy {
        url
        title
      }
      terms {
        url
        title
      }
    }
  }
`

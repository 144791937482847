import React from "react"

const RightArrow = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36.1 36.1">
      <path
        fill="none"
        stroke="#f1eb52"
        d="M17.7 35.7 35.4 18 17.7.4"
        data-name="Path 21443"
      />
    </svg>
  )
}

export default RightArrow

import React from "react"
// import style from './swiper.mod.scss';
import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination } from "swiper"
import CasesSlide from "./cases-slide"
import PlansSlide from "./plans-slide"
import ProductSlide from "./product-slide"
import "swiper/css/bundle"

const Slider = ({ settings = {}, slides, slideTemplate = "default" }) => {
  const defaults = {
    slidesPerView: 1,
    spaceBetween: 20,
    watchOverflow: true,
    pagination: {
      clickable: true,
      type: "bullets",
    },
    modules: [Pagination],
  }

  const swiperSettings = { ...defaults, ...settings }

  return (
    <div>
      <Swiper {...swiperSettings}>
        {slides.map((slide) => (
          <SwiperSlide key={slide.id}>
            {slideTemplate === "default" && <CasesSlide {...slide} />}
            {slideTemplate === "plan" && <PlansSlide {...slide} />}
            {slideTemplate === "product" && <ProductSlide {...slide} />}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default Slider

import React from "react"
import style from "./slide-surface.mod.scss"

const SlideSurface = ({
  children,
  isFav = false,
  hasBorder = false,
  invertBg = false,
}) => {
  const classes = [style.surface]

  isFav && classes.push(style["surface--favourite"])
  hasBorder && classes.push(style["surface--border"])
  invertBg && classes.push(style["surface--invert"])
  return (
    <>
      <div className={[...classes].join(" ")}>{children}</div>
    </>
  )
}

export default SlideSurface

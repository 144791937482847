import React from "react"
import Download from "../icons/download"
import style from "./slide-download.mod.scss"

const SlideDownload = ({ title, url }) => {
  return (
    <a className={style.download} href={url} target={"_blank"}>
      <Download />
      <span className={style.download__title}>{title}</span>
      <span className={style.download__helper}>Download</span>
    </a>
  )
}

export default SlideDownload

import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import style from "./product-slide.mod.scss"
import SlideSurface from "./slide-surface"
import BrandMark from "../icons/brand-mark"
import SlideDownload from "./slide-download"

const ProductSlide = ({
  id,
  title,
  image,
  altImages,
  downloads,
  paragraphText,
  characteristics,
}) => {
  return (
    <SlideSurface invertBg hasBorder>
      <div className={style.product}>
       <div>
          <div className={style.product__image_wrap}>
            <div className={style.product__image}>
              <GatsbyImage
                image={image.gatsbyImageData}
                alt={image.alt || ""}
                imgStyle={{ objectFit: `contain` }}
              />
              {altImages.length > 0 && (
                <div className={style.product__variants}>
                  {altImages.map((item) => (
                    <div className={style.product__variant}>
                      <GatsbyImage
                        image={item.image.gatsbyImageData}
                        alt={item.image.alt}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className={style.product__content}>
            <BrandMark />
            <h4>{title}</h4>
            <p className={style.product__text}>{paragraphText}</p>
          </div>
          <ul className={style.product__specs}>
            {characteristics.map((item) => (
              <li className={style.product__spec} key={item.id}>
                <h5>{item.title}</h5>
              </li>
            ))}
          </ul>
       </div>
        <ul className={style.product__downloads}>
          {downloads.map((item) => (
            <li className={style.product__download} key={item.id}>
              <SlideDownload title={item.title} url={item.pdf.url} />
            </li>
          ))}
        </ul>
      </div>
    </SlideSurface>
  )
}

export default ProductSlide

import Container from "../../components/container/container"
import React from "react"
import style from "./plans.mod.scss"
import Slider from "../../components/slider/slider"
import ButtonWrap from "../../components/global/button-wrap"

const Plans = ({ content: { plans, links, heading } }) => {
  return (
    <div className={style.plans}>
      <Container gutters pt={1} pb={1} width="large">
        <div className={style.plans__heading}>
          <h2>{heading}</h2>
        </div>
        <div className={style.plans__items}>
          <Slider
            slides={plans}
            slideTemplate={"plan"}
            settings={{
              breakpoints: {
                700: {
                  slidesPerView: 2,
                },
                1050: {
                  slidesPerView: 3,
                },
                1400: {
                  slidesPerView: 4,
                },
              },
            }}
          />
        </div>
        <div className={style.plans__buttons}>
          <ButtonWrap links={links} />
        </div>
      </Container>
    </div>
  )
}

export default Plans

import { GatsbyImage } from "gatsby-plugin-image"
import Container from "../../components/container/container"
import React from "react"
import style from "./hero.mod.scss"
import { motion } from "framer-motion"
import ButtonWrap from "../../components/global/button-wrap"

const Hero = ({
  content: { heading, paragraphText, backgroundImage, backgroundVideo, links },
}) => {
  const headingVariants = {
    offscreen: {
      x: -20,
      opacity: 0,
    },
    onscreen: {
      x: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.2,
        duration: 2.5,
        delay: 0.5,
      },
    },
  }
  const headingMobileVariants = {
    offscreen: {
      y: 30,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.2,
        duration: 2.5,
        delay: 0,
      },
    },
  }
  const imageVariants = {
    offscreen: {
      y: -150,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.2,
        duration: 1.5,
        // delay: 0.5,
      },
    },
  }

  return (
    <>
      <div className={style.hero}>
        <div className={style.hero__gradient}></div>
        <video autoPlay loop muted>
          <source src={backgroundVideo.url} type="video/mp4" />
        </video>
        <motion.div
          className={style.hero__content}
          variants={headingVariants}
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ amount: 0.2 }}
        >
          <Container gutters width="xlarge">
            <div className={style.hero__content_inner}>
              <div>
                <div>
                  <h1>{heading}</h1>
                </div>

                <p>{paragraphText}</p>
                <div>
                  <ButtonWrap links={links} />
                </div>
              </div>
            </div>
          </Container>
        </motion.div>
        <motion.div
          className={style.hero__image}
          variants={imageVariants}
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ amount: 0.2 }}
        >
          <Container gutters width="xlarge">
            <div className={style.hero__image_inner}>
              <GatsbyImage
                image={backgroundImage.gatsbyImageData}
                alt={backgroundImage.alt}
              />
            </div>
          </Container>
        </motion.div>
      </div>

      {/* MOBILE VERSION */}

      <div className={style.hero_mobile}>
        <motion.div
          className={style.hero_mobile__image}
          variants={imageVariants}
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ amount: 0.2 }}
        >
          <div className={style.hero_mobile__image_wrapper}>
            <GatsbyImage
              image={backgroundImage.gatsbyImageData}
              alt={backgroundImage.alt}
            />
          </div>
          <div className={style.hero_mobile__gradient} />
          <div className={style.hero_mobile__video}>
            <div className={style.hero_mobile__gradient} />
            <video autoPlay loop muted>
              <source src={backgroundVideo.url} type="video/mp4" />
            </video>
          </div>
        </motion.div>
        <motion.div
          className={style.hero_mobile__content}
          variants={headingMobileVariants}
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ amount: 0.2 }}
        >
          <Container gutters>
            {heading && <h1>{heading}</h1>}
            <p>{paragraphText}</p>
            <div className={style.hero_mobile__buttons}>
              <ButtonWrap links={links} />
            </div>
          </Container>
        </motion.div>
      </div>
    </>
  )
}

export default Hero

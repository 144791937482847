import * as React from "react"
import style from "./button-wrap.mod.scss"
import Button from "../global/button"

const ButtonWrap = ({ links }) => {
  return (
    //loop over links and render button
    <div className={style.button_wrap}>
        {links.map((link, index) => {
          const button = link.linkType[0];
          return <Button key={index} {...button} />
        })}
    </div>
  )
}

export default ButtonWrap

import Container from "../../components/container/container"
import React from "react"
import style from "./works.mod.scss"
import ButtonWrap from "../../components/global/button-wrap"
import RightArrow from "../../components/icons/right-arrow"

const Works = ({
  content: { items, links, heading, paragraphText, secondaryParagraphText },
}) => {
  return (
    <div className={style.works}>
      <Container gutters pt={1} pb={1} width="large">
        <div className={style.works__heading}>
          <h2>{heading}</h2>
        </div>
        <div className={style.works__content}>
          <p className={style.works__text}>{paragraphText}</p>
          <p className={style.works__secondary}>{secondaryParagraphText}</p>
        </div>
        <div className={style.works__items}>
          {items.map((item, i) => (
            <div key={`works-item-${i}`}>
              <div className={style.works__item}>
                <div className={style.works__item_top}>
                  <div className={style.works__item_icon}>
                    <img src={item.icon.url} alt={item.icon.alt} />
                  </div>
                  <div className={style.works__item_svg}>
                    <RightArrow />
                  </div>
                </div>
                <p className={style.works__item_text}>{item.text}</p>
              </div>
              <div key={`works-item-mobile${i}`} className={style.works__item_mobile}>
                <RightArrow />
              </div>
            </div>
          ))}
        </div>
        {links.length > 0 && (
          <div className={style.works__buttons}>
            <ButtonWrap links={links} />
          </div>
        )}
      </Container>
    </div>
  )
}

export default Works
